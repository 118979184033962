<template>
  <div>
    <FestgeldIndex />
  </div>
</template>

<script>
import FestgeldIndex from '../../../wettbewerbsvergleich/src/components/Wettbewerb/Festgeld/FestgeldIndex';

export default {
  name: 'WettbewerbFestGeldIndex',
  components: { FestgeldIndex },
};
</script>

<style lang="scss" scoped></style>
